<template>
  <ion-app>
    <ion-router-outlet />
  </ion-app>
</template>

<script>
import { Network } from '@capacitor/network';

import { mapActions } from 'vuex';

import { IonApp, IonRouterOutlet } from '@ionic/vue';

export default {
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet,
  },
  async created() {
    await this.$store.dispatch('auth/loadProfile');
    await this.$store.dispatch('prefs/loadFirstLogin');
    await this.$store.dispatch('prefs/loadLocale');
    await this.$store.dispatch('stores/init');
    await this.$store.dispatch('surveys/init');
    await this.$store.dispatch('tickets/init');
    await this.$store.dispatch('offline/loadState');
    if (this.$store.state.prefs.locale) {
      this.$i18n.locale = this.$store.state.prefs.locale;
    }
  },
  async mounted() {
    Network.getStatus().then((status) => {
      this.networkStatusChange(status);
    });

    Network.addListener('networkStatusChange', (status) =>
      this.networkStatusChange(status)
    );
  },
  methods: {
    ...mapActions('offline', ['networkStatusChange']),
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: normal;
  src: url('/public/assets/fonts/Poppins-Regular.ttf');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  src: url('/public/assets/fonts/Poppins-Light.ttf');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: url('/public/assets/fonts/Poppins-SemiBold.ttf');
}

:root {
  --ion-font-family: 'Poppins', 'Roboto', 'Helvetica Neue', sans-serif;
}

.ios ion-card-title {
  font-size: 20px;
}

@for $i from 0 through 8 {
  .odn-pad-#{$i * 2} {
    padding: #{$i * 2px};
  }
  .odn-pal-#{$i * 2} {
    padding-left: #{$i * 2px};
  }
  .odn-pat-#{$i * 2} {
    padding-top: #{$i * 2px};
  }
  .odn-par-#{$i * 2} {
    padding-right: #{$i * 2px};
  }
  .odn-pab-#{$i * 2} {
    padding-bottom: #{$i * 2px};
  }
  .odn-pax-#{$i * 2} {
    padding-left: #{$i * 2px};
    padding-right: #{$i * 2px};
  }
  .odn-pay-#{$i * 2} {
    padding-top: #{$i * 2px};
    padding-bottom: #{$i * 2px};
  }

  .odn-mar-#{$i * 2} {
    margin: #{$i * 2px};
  }
  .odn-mal-#{$i * 2} {
    margin-left: #{$i * 2px};
  }
  .odn-mat-#{$i * 2} {
    margin-top: #{$i * 2px};
  }
  .odn-mar-#{$i * 2} {
    margin-right: #{$i * 2px};
  }
  .odn-mab-#{$i * 2} {
    margin-bottom: #{$i * 2px};
  }
  .odn-max-#{$i * 2} {
    margin-left: #{$i * 2px};
    margin-right: #{$i * 2px};
  }
  .odn-may-#{$i * 2} {
    margin-top: #{$i * 2px};
    margin-bottom: #{$i * 2px};
  }
}

.odn-code {
  font-family: Courier, monospace !important;
}
</style>
